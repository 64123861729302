.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: fixed !important;
  left: 0px;
  z-index: 11;

  .logo {
    background-repeat: no-repeat;
    background-position: center;
    // background-image: url(../../assets/images/logo/logo.svg);
    background-size: 100px;
  }
}
.mk-nav {
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;
  background: #ffffff;
  height: 50px;
}

.hideOnMobile {
  display: none;
}
.main-content {
  margin: 16px;
  min-height: calc(100vh - 90px);
}
.grupp-content {
  padding-top: 50px;
  background: #ffffff;
}
@media only screen and (min-width: 1024px) {
  .hideOnMobile {
    display: block;
  }
  .grupp-content {
    margin-left: 200px;
    background: none;
  }
  .main-content {
    margin: 24px 16px;
    padding: 24px;
  }
  .mk-nav {
    width: calc(100% - 200px);
    left: 200px;
    top: 0;
    height: 64px;
  }

  .hideOnDesktop {
    display: none;
  }
}

.hideOnDesktop {
  display: block;
}

.menu {
  .ant-menu-item {
    color: #969ba0;
    height: 53px;
    font-size: 16px;
    margin-left: 0;
    margin-left: 0;
    margin-bottom: 10px;

    /*    a.active,
      a:hover {
        color: #000000;
      }*/

    &:hover {
      color: #000000;
      background: transparent !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: scaleY(0.0001);
      opacity: 0;
      border-left: solid 6px #000000;
      border-radius: 0 10px 10px 0;
    }

    &::after {
      border: 0;
    }

    .anticon {
      width: 20px;
      font-size: 20px;
    }

    &.ant-menu-item-selected {
      background-color: #fafbff;
      color: #000000;

      a {
        color: #000000;
      }

      &::before {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  .ant-menu-submenu {
    color: #969ba0;

    .ant-menu-submenu-title {
      color: #969ba0;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #969ba0;
      }
    }
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    background-color: #fff;
    color: #000000;

    .ant-menu-submenu-title {
      color: #000000;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #000000;
      }
    }

    .ant-menu-item-active,
    .ant-menu-submenu-title:hover {
      color: #000000;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #000000;
      }
    }
  }
}

.grupp-drawer {
  .ant-drawer-content-wrapper {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: none;
  }
}

.card-value {
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  line-height: 15px;
  letter-spacing: -0.04em;
  color: #8291ab;
}

.grupp-button-bg {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.upload-progress {
  background: linear-gradient(
    258.28deg,
    rgba(7, 156, 190, 0.2) 10.99%,
    rgba(0, 73, 127, 0.2) 111.23%
  );
  position: absolute;
  height: 150px;
  left: 0px;
  top: 0px;
  transition: width 2s;
  border-radius: 8px;
  //animation: animate 5s forwards;
}
.details-bg {
  width: 100%;
  min-height: 140px;
  border-radius: 0 0 45% 45% / 0 0 30% 30%;
  //position: absolute
}
.no-opacity-bg svg rect {
  opacity: 0;
}
.button-icon span:nth-child(even) {
  margin-left: 0;
}
.currency {
  .ant-select-selector {
    border: 1px solid #cacbcc !important;
    border-radius: 8px !important;
  }
  .ant-select-arrow {
    right: 5px;
  }
}
