$mk-bg-gray: #a3abb7;
$mk-btn-primary: #021230;

// button
.mikro-btn-primary {
  background-color: #021230;
}

.mikro-black {
  color: #021230;
}

.details-color {
  height: 110px;
  border-radius: 0 0 50% 50%;
}

.calendar {
  margin: auto;
  border-radius: 8px;
  background-color: #f4f9f8;
  tr {
    color: #031b4a;
    font-size: 12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.month {
  line-height: 18px;
  letter-spacing: 0em;
}

.cashback-shadow {
  box-shadow: 0px 3px 16px 0px #00000012;
}

.selectedRange {
  background-color: #348c0a;
  transition: background-color 0.3s ease;
}

/*.selectedRange + td {
  background-color: lightblue;
  transition: background-color 0.3s ease;
}

.selectedRange + td + td {
  background-color: lightblue;
  transition: background-color 0.3s ease; 
}*/
