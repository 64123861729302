.ant-form-item {
  margin-bottom: 20px;
}
.ant-picker-range .ant-picker-active-bar {
  background: #000;
}

.auth-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #cacbcc;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  font-size: 14px;
  background: #fafafa;
  color: #505f79;

  .ant-input-number-input {
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    background: rgba(0, 0, 0, 0.03);
    border: 0;
    height: 48px;
    font-size: 16px;
  }

  &.filled.ant-input {
    font-size: 14px;
  }
  &.ant-input-password {
    input.ant-input {
      background: transparent;
    }
  }
  &.filled.ant-input-password {
    font-size: 15px;
  }

  &.filled.ant-picker {
    .ant-picker-input {
      font-size: 15px;
    }
  }

  &.filled {
    .ant-input-number-input {
      font-size: 14px;
    }
  }
  .ant-input {
    background: transparent;
  }
}

.grupp-select {
  &.ant-select .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    border: 1px solid #cacbcc !important;
    background: #fafafa !important;
    align-items: center;
    font-size: 14px;
    padding: 0 15px;
    box-shadow: none !important;
    text-align: left;
    width: 100%;
    color: #505f79;
  }
}

.item-bank {
  .ant-select-selector .ant-select-selection-item {
    height: 30px;
  }
}

.grupp-textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #edeef0;
  font-size: 14px;
  background: #fafafa;
  color: #505f79;
}

.ant-col.ant-form-item-label {
  label {
    font-weight: 500;
    font-size: 1rem;
  }
}

.auth-count-down {
  span {
    font-weight: 700;
  }
}
.select-code > .ant-select-selector > .ant-select-selection-item {
  height: 33px;
}

.auth-label {
  display: none;
  &.filled {
    display: inline-block;
    position: absolute;
    top: -17px;
    left: 12px;
    font-size: 12px;
    //color: #1377c4;
  }
}

// select style
.select {
  .ant-form-item-control-input-content {
    border: 1px solid #edeef0;
    background: #fafafa;
    border-radius: 8px;
  }
}

.ant-select .ant-select-selection-item > * {
  overflow: visible;
}

.auth-button-disabled,
.ant-btn[disabled].auth-button-disabled {
  background: rgba(2, 18, 48, 0.2);
  color: #fff;
}
.ant-btn {
  line-height: 1.5715;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  touch-action: manipulation;
  padding: 4px 15px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.grupp-btn {
    border-radius: 5px;
  }
  &.contact-btn {
    border-radius: 25px;
    &:focus,
    &:hover {
      border-color: #fff;
      color: #fff;
      background: black;
    }
  }
}
.ant-form-item-explain {
  //text-align: left !important;
  font-size: 12px !important;
}
.testImage {
  height: 70px;
  width: 80px;
  border-radius: 8px;
  position: relative;
}
.comment-area:focus {
  border: none;
  box-shadow: none;
}

.filter-modal {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-header {
      border-radius: 8px;
      border: 0;
      padding-bottom: 8px;
    }
  }
}
.rounded-profile {
  border-radius: 5.5rem !important ;
  .ant-upload {
    padding: 0 !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield; /* Chrome, Safari, Opera */
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Standard syntax */
}

.new-modal {
  .ant-modal-content {
    border-radius: 16px;

    .ant-modal-header {
      border-radius: 8px;
      border: 0;
      padding-bottom: 8px;
    }
  }
}

.new-otp-box {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1.8px solid #021230 !important;
  font-weight: 500 !important;
}

.countdown-box {
  span {
    font-weight: 300;
  }
}
