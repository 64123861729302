@import url("https://use.typekit.net/jkv5qjh.css");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,500;1,600&display=swap");

* {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.grupp-gray {
  color: #505f79;
}

.auth-customize-text {
  color: var(--custom-text-color);
}

.onboard-subtitle {
  color: #8291ab;
  font-size: 12px;
}

.font-small {
  font-size: 12px;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #2a2a2a !important;
}
.ant-tabs-ink-bar {
  height: 3px !important;
  background-color: #0d0d0e !important;
}
.ant-tabs-tab {
  width: 100%;
  justify-content: center;
}

.custom {
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        justify-content: space-around;
        width: 100%;
      }
    }
  }
}
.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 150%;
  color: #021230;
}
.success_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.grupp-shadow {
  border-radius: 8px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.06);
}
.vertical-line {
  width: 2px;
  height: auto;
  margin: 3px 0;
  background-color: transparent;
  border-left: 1px dashed #26847d;
  position: relative;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #26847d;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: -5px;
}

.grupp-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      border-radius: 50px 50px 0px 0px;
      box-shadow: none;
    }
  }
}
.resize-svg {
  transform: scale(0.6);
}
.radio_button-shadow {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.grupp-radio-button {
  .ant-radio-button-wrapper-checked {
    background-color: var(--selected-bgcolor);
    box-shadow: none !important;
    &:focus {
      border: none;
      outline: 0;
    }
    .radio-text {
      color: #ffffff;
    }
  }
}
.radio-text {
  color: #021230;
}
.three-dot:after {
  content: "\2026";
  font-size: 30px;
}
.test:after {
  content: "\22EE";
  font-size: 40px;
}

.user-card {
  background-image: url("../assets/user-backdrop.png");
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat;
}

.cashpoint-col {
  display: block;
  flex: 0 0 45%;
  max-width: 45%;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
