@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./auth";
@import "./variables";
@import "./app.scss";
@import "./layout";
@import "./formInputStyles";
@import "./dashboard";
@import "./transaction";
