.table-input {
  width: 100%;
  border-radius: 25px;
  font-size: 13px;

  .ant-input {
    background-color: #fafafa;
    border-color: #a1a1a1;
    outline: 0;
    height: 35px;
  }

  .ant-input-group-addon {
    button {
      background-color: #f9f9f9;
      border-color: #a1a1a1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0;
      height: 35px;
      border-radius: 0 20px 20px 0 !important;
    }
  }
  .ant-input-group {
    border-radius: 20px;
    input {
      border-radius: 20px 0 0 20px;
    }
  }

  .ant-select-selector {
    border-radius: 20px !important;
    height: 35px !important;
    align-items: center;
    font-size: 13px;
    color: #636363;
    background-color: #f9f9f9 !important;
    border-color: #a1a1a1 !important;
  }

  .ant-input-number-input {
    width: 100%;
    height: 35px;
    border-radius: 20px;
    font-size: 13px;
    background-color: #f9f9f9;
    border-color: #a1a1a1;
  }
}
.search-input-account {
  width: 100%;
  border-radius: 100px;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.03);
}
