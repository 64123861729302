.card-shell {
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.mikro-bills-text-color {
  color: #031b4a;
}

.mobile-table-bg {
  background: black;
  margin: 20px -8px 0px -8px;
  color: white;
  padding: 1rem;
  align-items: baseline;
  border-radius: 12px 12px 0px 0px;
}

.resolution-time {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
}

@media (min-width: 640px) {
  .mobile-table-bg {
    background: transparent;
    margin: 0;
    color: #021230;
    padding: 0 0 0.7rem 0;
    align-items: center;
    border-radius: 0;
  }

  .grupp-drawer {
    .ant-drawer-content-wrapper {
      width: 500px;
      margin: auto;
    }
  }
}

.ant-form input[type="file"] {
  opacity: 0;
  opacity: 0;
  z-index: 1;
  height: 70px;
  cursor: pointer;
}

.grupp-business-color {
  color: #021230;
}

.grupp-border {
  border-bottom: 1px solid #f4f4f4;
}

// .merchant-step {
//   .ant-steps-item-container {
//     .ant-steps-item-icon {
//       &.ant-steps-item-finish {
//         background: #000000;
//         border: #000000;
//       }
//       span {
//         color: #fff;
//         top: -3.5px;
//       }
//     }
//   }
// }
// .ant-steps-item-finish
//   > .ant-steps-item-container
//   > .ant-steps-item-tail:after {
//   background-color: black !important;
// }
// .ant-steps-item-finish .ant-steps-item-icon {
//   background: #000000;
//   border-color: #000000;
// }
// .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
//   background: #000000;
//   border-color: #000000;
//   span {
//     display: none;
//   }
// }
// .loan-terms {
//   ul {
//     list-style: disc;
//   }
//   p {
//     margin-bottom: 0.5rem;
//   }
// }
.svga {
  svg path {
    fill: var(--svg-color);
  }
}
