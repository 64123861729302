.anl-select {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: 0;
    background-color: #e9f3ff;
    color: #172327;
    font-size: 13px;
  }
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 33px;
}

.filter-form {
  label {
    color: rgba($color: #000000, $alpha: 0.6);
  }

  .ant-input {
    background-color: #f9f9f9;
    border-color: #a1a1a1;
    outline: 0;
    height: 35px;
    border-right: 0;
    // padding: 1.5rem 0;
  }

  .ant-input-group-addon {
    padding: 1.5rem 0;

    button {
      background-color: #f9f9f9;
      border-color: #a1a1a1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0;
      height: 35px;
      border-radius: 0 20px 20px 0 !important;
      padding-top: 5rem;

      .anticon {
        color: black;
      }
    }
  }
  .ant-input-group {
    border-radius: 20px;
    input {
      border-radius: 20px 0 0 20px;
    }
  }
}

.txn-ref .ant-form-item-control {
  .ant-form-item-explain {
    color: rgba($color: #000000, $alpha: 0.3);
    font-size: 12px;
    margin-left: 20px;
    padding: 5px 0 15px 0;
  }
}
.option-dropdown {
  ul {
    border-radius: 8px;
  }
}
